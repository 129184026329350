import React, { useEffect, useState } from "react";
import {
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { fetch } from "../../service/utils";
import EditOptoAssement from "../../website/components/modals/EditOptoAssement";
import ForwardForm from "../../website/components/modals/ForwardForm";
import { PropagateLoader } from "react-spinners";
import EditRegistrationPatient from "../components/modals/EditRegistrationPatient";
import { TbEyeEdit } from "react-icons/tb";

function OptometristDashboardList(props) {
  const [addVisionDetail, setAddVisionDetail] = useState(false);
 

  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");

  const [forwardFormOpen, setForwardFormOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPatient, setFilteredPatient] = useState([]);
  const [optoData, setOptoData] = useState([]);

  const [editOptoDetail, setEditOptoDetail] = useState({})
  const [optoId, setOptoId] = useState("")

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase() || "";
    setSearchQuery(query);

    const filtered =
      [] &&
      [].length > 0 &&
      []?.filter((pt) => pt?.fname?.toLowerCase().includes(query));
    setFilteredPatient(filtered);
  };

  const getOptoDetails = async (e) => {
    setIsLoading(true);
    setNetworkError("");

    try {
      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/optometrist/get_opto_assessment", "get", null, headers);
      setIsLoading(false);
      if (response.data.success === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setOptoData(response.data?.data);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again later.";
      setNetworkError(errorMessage);
      toast.error(errorMessage);
      console.log(err);
    }
  };

  useEffect(() => {
    getOptoDetails();
  }, []);

  return (
    <>
      <section className="-mx-4 flex flex-wrap p-4">
        <div className="inline-block w-[100%]  border-[1px] border-gray-300 overflow-auto">
          <div className="overflow-auto">
            <div className="flex min-w-[100%]  items-center justify-between py-2 mb-3 bg-[#0d5781]">
              <div className="font-semibold text-lg px-3 text-white ">
                Optometrist Dashboard
              </div>

              <Link to="/admin/dashboard/patient-optometrist" className="mr-10">
                <button className="px-3 py-[6px]  text-base font-medium text-white bg-[#10a659] rounded-md shadow-md focus:outline-none focus:ring-2">
                  Optometrist List
                </button>
              </Link>
            </div>
            {/* <div className="flex min-w-full items-center justify-end  mb-2">
              <div className="border-[1px] border-[#0d5781] w-[20%] rounded-[4px] mr-10">
                <input
                  type="text"
                  placeholder="Search Name, Contact, City..."
                  className="px-2 py-[5px] rounded-[4px] w-full "
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div> */}
            {isLoading ? (
              <div className="flex justify-center h-screen items-center">
                <PropagateLoader color="#36d7b7" loading={true} size={15} />
              </div>
            ) : networkError ? (
              <div className="text-red-600 text-center mt-16 font-semibold">
                {networkError}
              </div>
            ) : (
              <div className="overflow-auto">
                <table class="table table-hover">
                  <thead className="bg-[#0d5781] text-white">
                    <tr>
                      <th scope="col" className="w-[3%] text-center font-medium">
                        #
                      </th>
                      <th scope="col" className="w-[15%] text-center font-medium">
                        Full Name
                      </th>
                      <th scope="col" className="w-[5%] text-center font-medium">
                        Age
                      </th>
                      <th scope="col" className="w-[5%] text-center font-medium">
                        Gender
                      </th>
                      <th scope="col" className="w-[8%] text-center font-medium">
                        Contact
                      </th>
                      <th scope="col" className="w-[20%] text-center font-medium">
                        Left Eye
                      </th>
                      <th scope="col" className="w-[20%] text-center font-medium">
                        Right Eye
                      </th>
                      <th
                        scope="col"
                        className="w-[17%]  text-center font-medium"
                      >
                        Complaints
                      </th>
                      <th
                        scope="col"
                        className="w-[7%] text-center  font-medium"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {optoData &&
                      optoData.length > 0 &&
                      optoData?.map((pt, ind) => {

                        return (
                          <tr>
                            <td scope="row" className="text-center border-[1px]">
                              {ind + 1}
                            </td>
                            <td>{pt?.patientId?.fName} {pt?.patientId?.lName}</td>
                            <td className="text-center border-[1px]">{pt?.patientId?.age}</td>
                            <td className="text-center border-[1px]">{pt?.patientId?.gender}</td>
                            <td className="border-[1px]">{pt?.patientId?.contact}</td>
                            <td className="border-[1px]">
                              {pt?.leftEyeVision && (
                                <>
                                  {Object.entries(pt.leftEyeVision).map(([key, value], index) => (
                                    <span key={index}>
                                      {key}: {value}
                                      <br />
                                    </span>
                                  ))}
                                </>
                              )}
                            </td>

                            <td className="border-[1px]">
                              {pt?.leftEyeVision && (
                                <>
                                  {Object.entries(pt.rightEyeVision).map(([key, value], index) => (
                                    <span key={index}>
                                      {key}: {value}
                                      <br />
                                    </span>
                                  ))}
                                </>
                              )}
                            </td>
                            <td className="text-center border-[1px]">{pt.patientComplaint}</td>

                            <td className="text-center border-[1px]">
                              <div className="flex justify-center items-center space-x-2">
                                <TbEyeEdit
                                  className="w-6 h-6 ml-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                                  onClick={() => { setEditOptoDetail(pt); setAddVisionDetail(true); }}
                                />
                               
                                <ArrowTopRightOnSquareIcon
                                  className="w-6 h-6 ml-2 text-green-500 hover:text-green-800 cursor-pointer"
                                  onClick={() => { setOptoId(pt._id); setForwardFormOpen(true) }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
      <EditOptoAssement
        addVisionDetail={addVisionDetail}
        setAddVisionDetail={setAddVisionDetail}
        editOptoDetail={editOptoDetail}
        optoDetails={getOptoDetails}
        patientId={editOptoDetail?.patientId?._id}
      />
      <ForwardForm
        forwardFormOpen={forwardFormOpen}
        setForwardFormOpen={setForwardFormOpen}
        getData={getOptoDetails}
        id={optoId}
        url={"/optometrist/forward-assessment/"}
      />
      
    </>
  );
}

export default OptometristDashboardList;
