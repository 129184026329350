/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useInput } from "../../hook/input-hook";
import { useSelector } from "react-redux";
import { fetch } from "../../service/utils";
import toast from "react-hot-toast";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import EyeIcon from "../components/eye-icon/EyeIcon";

const isOldPassword = (value, isTouchOldPassword) => {
  if (value === "") {
    return {
      validated: false,
      message: "Please Enter Your Old Password",
    };
  }
  if (isTouchOldPassword) {
    return {
      validated: false,
      message: "Please Enter Your Old Password",
    };
  } else if (value.length < 6) {
    return {
      validated: false,
      message: "Password Should Contain at least 6 Characters!",
    };
  } else {
    return { validated: true, message: "" };
  }
};
const isPassword = (value, isTouchPassword) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your New Password" };
  }
  if (isTouchPassword) {
    return { validated: false, message: "Please Enter Your New Password" };
  } else if (value.length < 6) {
    return {
      validated: false,
      message: "Password Should Contain at least 6 Characters!",
    };
  } else {
    return { validated: true, message: "" };
  }
};
const isConfirmPassword = (value, isTouchConfirmPassword) => {
  if (value === "") {
    return {
      validated: false,
      message: "Please Confirm Your New Password",
    };
  } else if (isTouchConfirmPassword) {
    return {
      validated: false,
      message: "Please Confirm Your Password",
    };
  } else {
    return { validated: true, message: "" };
  }
};

function ChangePwd() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const [isOldPwdTouch, setisOldPwdTouch] = useState("");
  const [isNewPwdTouch, setisNewPwdTouch] = useState("");
  const [isConfPwdTouch, setisConfPwdTouch] = useState("");

  const user_id = useSelector((state) => state.user.userData);
  const [subError, setSubError] = useState("");

  const {
    hasError: hasErrorOldPassword,
    enterValue: enterValueOldPassword,
    message: OldpasswordMessage,
    onChangeHandler: onChangeHandlerOldPassword,
    reset: resetOldPassword,
    isTouch: isTouchOldPassword,
    onBlurHandler: onBlurHandlerOldPassword,
  } = useInput(isOldPassword, setNetworkError, setisOldPwdTouch);
  const {
    hasError: hasErrorPassword,
    enterValue: enterValuePassword,
    message: passwordMessage,
    onChangeHandler: onChangeHandlerPassword,
    reset: resetPassword,
    isTouch: isTouchPassword,
    onBlurHandler: onBlurHandlerNewPassword,
  } = useInput(isPassword, setNetworkError, setisNewPwdTouch);

  const {
    hasError: hasErrorConfirmPassword,
    enterValue: enterValueConfirmPassword,
    message: ConfirmpasswordMessage,
    onChangeHandler: onChangeHandlerConfirmPassword,
    reset: resetConfirmPassword,
    isTouch: isTouchConfirmPassword,
    onBlurHandler: onBlurHandlerConfPassword,
  } = useInput(isConfirmPassword, setNetworkError, setisConfPwdTouch);

  useEffect(() => {
    setSubError("");
    setisOldPwdTouch("");
    setisNewPwdTouch("");
    setisConfPwdTouch("");
  }, [enterValueOldPassword, enterValuePassword, enterValueConfirmPassword]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setNetworkError(false);
    setIsLoading(true);
    if (
      hasErrorOldPassword === true ||
      hasErrorPassword === true ||
      hasErrorConfirmPassword === true
    ) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (
      !isTouchOldPassword ||
      !isTouchPassword ||
      !isTouchConfirmPassword
    ) {
      if (!isTouchOldPassword) {
        setisOldPwdTouch("Please Enter Your Old Password");
      }
      if (!isTouchPassword) {
        setisNewPwdTouch("Please Enter Your New Password");
      }
      if (!isTouchConfirmPassword) {
        setisConfPwdTouch("Please Confirm Your New Password");
      }
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (enterValuePassword !== enterValueConfirmPassword) {
      setIsLoading(false);
      setSubError("passwords");
      return false;
    }

    try {
      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/admin/change-pwd",
        "post",
        {
          user_id:user_id?._id,
          oldpwd: enterValueOldPassword,
          newpwd: enterValuePassword,
          renewpwd: enterValueConfirmPassword,
        },
        headers
      );
      setIsLoading(false);
      if (response && response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
        toast.error(response.data.message);
      }
      setIsLoading(false);
      if(user_id?.roleId === 3){
          navigate("/optometrist");

      }else{
        navigate("/registration");

      }
      toast.success("Password Changed Successfully!");
      setNetworkError("");
      resetOldPassword();
      resetPassword();
      resetConfirmPassword();
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Unable to update password!");
      }
    }
  };

  const resetFieldHandler = () => {
    setSubError("");
    resetOldPassword();
    resetPassword();
    resetConfirmPassword();
  };

  const [typeOldPwd, setTypeOldPwd] = useState("password");
  const [typeNewpwd, setTypeNewPwd] = useState("password");
  const [typeConfPwd, setTypeConfPwd] = useState("password");

  const handleToggleOldPwd = (id) => {
    if (id === "cust_oldPwd") {
      if (typeOldPwd === "password") {
        setTypeOldPwd("text");
      } else {
        setTypeOldPwd("password");
      }
    }
    if (id === "cust_newPwd") {
      if (typeNewpwd === "password") {
        setTypeNewPwd("text");
      } else {
        setTypeNewPwd("password");
      }
    }
    if (id === "cust_confPwd") {
      if (typeConfPwd === "password") {
        setTypeConfPwd("text");
      } else {
        setTypeConfPwd("password");
      }
    }
  };

  const onKeyDownHandlerOldPwd = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // submitRegisterHandler(e);
    }
  };
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mx-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Change Your Password
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={submitHandler}>
            <div className="form-group py-2 col-md-12">
              <input
                type={typeOldPwd}
                className="form-control"
                id="cust_oldPwd"
                name="cust_oldPwd"
                placeholder="Please Enter Your Old Password"
                onChange={onChangeHandlerOldPassword}
                value={enterValueOldPassword}
                onKeyDownHandler={onKeyDownHandlerOldPwd}
                handleToggle={handleToggleOldPwd}
                onBlur={() => {
                  onBlurHandlerOldPassword();
                  setisOldPwdTouch("");
                }}
              />
              <div
                className="-mr-10 -mt-[6px] flex justify-end items-end cursor-pointer "
                onClick={() => handleToggleOldPwd("cust_oldPwd")}
              >
                <EyeIcon type={typeOldPwd} />
              </div>
              {hasErrorOldPassword && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[7px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[7.5px]">
                    {OldpasswordMessage}
                  </p>
                </div>
              )}
              {isOldPwdTouch && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                    {isOldPwdTouch}
                  </p>
                </div>
              )}
            </div>
            <div className="form-group py-2 col-md-12">
              <input
                type={typeNewpwd}
                className="form-control"
                id="cust_newPwd"
                name="cust_newPwd"
                placeholder="Please Enter Your New Password"
                onChange={onChangeHandlerPassword}
                value={enterValuePassword}
                onKeyDownHandler={onKeyDownHandlerOldPwd}
                handleToggle={handleToggleOldPwd}
                onBlur={() => {
                  onBlurHandlerNewPassword();
                  setisNewPwdTouch("");
                }}
              />
              <div
                className="-mr-10 -mt-[6px] flex justify-end items-end cursor-pointer "
                onClick={() => handleToggleOldPwd("cust_newPwd")}
              >
                <EyeIcon type={typeNewpwd} />
              </div>
              {hasErrorPassword && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[7px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[7.5px]">
                    {passwordMessage}
                  </p>
                </div>
              )}
              {isNewPwdTouch && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                    {isNewPwdTouch}
                  </p>
                </div>
              )}
            </div>
            <div className="form-group py-2 col-md-12">
              <input
                type={typeConfPwd}
                className="form-control"
                id="cust_confPwd"
                name="cust_confPwd"
                placeholder="Please Enter Your Confirm Password"
                onChange={onChangeHandlerConfirmPassword}
                value={enterValueConfirmPassword}
                onKeyDownHandler={onKeyDownHandlerOldPwd}
                handleToggle={handleToggleOldPwd}
                onBlur={() => {
                  onBlurHandlerConfPassword();
                  setisConfPwdTouch("");
                }}
              />
              <div
                className="-mr-10 -mt-[6px] flex justify-end items-end cursor-pointer "
                onClick={() => handleToggleOldPwd("cust_confPwd")}
              >
                <EyeIcon type={typeConfPwd} />
              </div>
              {hasErrorConfirmPassword && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[7px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[7.5px]">
                    {ConfirmpasswordMessage}
                  </p>
                </div>
              )}
              {isConfPwdTouch && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                    {isConfPwdTouch}
                  </p>
                </div>
              )}
            </div>
            
            {subError === "passwords" && (
              <div className="flex ml-[0.5px]">
                <ExclamationTriangleIcon className="h-4 w-4 mt-[-20px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                <p className="text-red-600 text-xs  leading-16 mt-[-19.5px]">
                  Passwords do not match
                </p>
              </div>
            )}

            <div className="grid grid-cols-2 gap-x-4 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
              <div>
                <button
                  className="flex w-full justify-center rounded-md bg-red-600 duration-700  px-3 py-2 text-[16px]  font-medium leading-6 text-white shadow-sm hover:bg-[#0d5781] focus:outline-none focus:bg-[#0d5781] "
                  onClick={resetFieldHandler}
                >
                  Reset
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-[#10a659] hover:bg-[#0d5781] duration-700   w-full rounded-md  px-2 py-2 text-center text-[16px] font-medium text-white shadow-sm focus:outline-none focus:bg-[#0d5781]"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
          <div className="text-red-500 text-sm text-center pt-5">
            {subError === "fields" && (
              <strong className="text-red-500 text-sm text-center">
                Please Fill All The Fields Appropriately.
              </strong>
            )}
            {networkError && (
              <strong className="text-red-500 text-sm text-center">
                {networkError}
              </strong>
            )}
            {isLoading && (
              <strong className="text-blue-800 text-sm text-center">
                Please Wait, Updating Your Password...
              </strong>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePwd;
