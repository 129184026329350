import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";

import { useInput } from "../../../hook/input-hook";
import { couponValueNumber, emailreg, numberreg } from "../../../service/validations/validation";
import Input from "../../../admin/components/input/Input.component";
import { MoonLoader } from "react-spinners";
import { fetch } from "../../../service/utils";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";



const isFName = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Your First Name" };
    } else {
        return { validated: true, message: "" };
    }
};

const isContact = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Your Contact Number" };
    } else if (!numberreg.test(value)) {
        return { validated: false, message: "Enter 10 digits Contact number" };
    } else {
        return { validated: true, message: "" };
    }
};
const isAge = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Your Age" };
    } else if (!couponValueNumber.test(value)) {
        return { validated: false, message: "Your Age is Invalid" };
    } else {
        return { validated: true, message: "" };
    }
};

const isCity = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Your City" };
    } else {
        return { validated: true, message: "" };
    }
};
const isGender = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Your Gender" };
    } else {
        return { validated: true, message: "" };
    }
};
const isPaymentMode = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Your Payment Mode" };
    } else {
        return { validated: true, message: "" };
    }
};
const isSystemicHistory = (value) => {
    if (value === "") {
        return { validated: false, message: "Please Enter Patient Systemin History." };
    } else {
        return { validated: true, message: "" };
    }
};


export default function EditRegistrationPatient({
    editRegFormOpen,
    setEditRegFormOpen,
    // patientId,
    editPtDetail,
    getReceptionDetails,
}) {
    const cancelButtonRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [networkError, setNetworkError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");


    const [gender, setGender] = useState("");
    const [payment, setPayment] = useState("");

    const [genderError, setGenderError] = useState("");
    const [paymentError, setPaymentError] = useState("");

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setGenderError("")
    };
    const handlePaymentChange = (event) => {
        setPayment(event.target.value);
        setPaymentError("")
    };
    const [fNameTouch, setFNameTouch] = useState("");
    const [contactTouch, setContactTouch] = useState("");
    const [ageTouch, setAgeTouch] = useState("");
    const [cityTouch, setCityTouch] = useState("");
    const [genderTouch, setGenderTouch] = useState("");
    const [paymentModeTouch, setPaymentModeTouch] = useState("");
    const [systemicHistoryTouch, setSystemicHistoryTouch] = useState("");

    
    const {
        hasError: hasErrorFName,
        enterValue: enterValueFName,
        message: FNameMessage,
        onChangeHandler: onChangeHandlerFName,
        reset: resetFName,
        isTouch: isTouchFName,
        setDirect: setDirectFName,
        onBlurHandler: onBlurHandlerFName,
    } = useInput(isFName, setNetworkError, setFNameTouch);


    const {
        hasError: hasErrorContact,
        enterValue: enterValueContact,
        message: ContactMessage,
        onChangeHandler: onChangeHandlerContact,
        reset: resetContact,
        isTouch: isTouchContact,
        setDirect: setDirectContact,
        onBlurHandler: onBlurHandlerContact,
    } = useInput(isContact, setNetworkError, setContactTouch);

    const {
        hasError: hasErrorAge,
        enterValue: enterValueAge,
        message: AgeMessage,
        onChangeHandler: onChangeHandlerAge,
        reset: resetAge,
        isTouch: isTouchAge,
        setDirect: setDirectAge,
        onBlurHandler: onBlurHandlerAge,
    } = useInput(isAge, setNetworkError, setAgeTouch);

    const {
        hasError: hasErrorCity,
        enterValue: enterValueCity,
        message: CityMessage,
        onChangeHandler: onChangeHandlerCity,
        reset: resetCity,
        isTouch: isTouchCity,
        setDirect: setDirectCity,

        onBlurHandler: onBlurHandlerCity,
    } = useInput(isCity, setNetworkError, setCityTouch);

    


 

    const {
        hasError: hasErrorSystemicHistory,
        enterValue: enterValueSystemicHistory,
        message: SystemicHistoryMessage,
        onChangeHandler: onChangeHandlerSystemicHistory,
        reset: resetSystemicHistory,
        isTouch: isTouchSystemicHistory,
        setDirect: setDirectSystemicHistory,
        onBlurHandler: onBlurHandlerSystemicHistory,
    } = useInput(isSystemicHistory, setNetworkError, setSystemicHistoryTouch);
    
    useEffect(() => {
        setSuccessMessage("");
    }, [editRegFormOpen]);

    useEffect(() => {
        setDirectFName(editPtDetail?.fName)
        setDirectContact(editPtDetail?.contact);
        setDirectAge(editPtDetail?.age);
        setDirectCity(editPtDetail?.city);
        setGender(editPtDetail?.gender);
        setPayment(editPtDetail?.payId);
        setDirectSystemicHistory(editPtDetail?.systemicHistory);

    }, [editRegFormOpen]);

    const EditPetientDetails = async () => {
        setLoading(true);
        setNetworkError("");
        setLoadingMessage("Updating an Entry...");
        if (
            hasErrorFName === true ||
            hasErrorAge === true ||
            hasErrorContact === true ||
            hasErrorCity === true 
        ) {
            setLoading(false);
            setLoadingMessage("");
            setNetworkError("Please Fill All Fields Appropriately!");
            return false;
        }
        if (!gender) {
            setLoading(false);
            setGenderError("Please Select Your Gender!")
            return false;
        }
        if (!payment) {
            setPaymentError("Please Select Payment Mode!")
            setLoading(false);
            return false;
        }
       
        try {
            const body = {
                fName: enterValueFName,
                age: parseInt(enterValueAge),
                gender: gender,
                contact: parseInt(enterValueContact),
                city: enterValueCity,
                systemicHistory: enterValueSystemicHistory,
                payId: payment,
            };
            const token = localStorage.getItem("beenayi_token");
            const headers = { Authorization: `Bearer ${token}` };
            const response = await fetch(`/reception/edit-patients/${editPtDetail._id}`, "put", body, headers);
            if (response.ok === false) {
                setLoading(false);
                setNetworkError(response.data.message);
            }
            setLoading(false);
            setSuccessMessage(response.data.message);
            getReceptionDetails();
            setEditRegFormOpen(false);
            resetfield()
            toast.success("Successfully Update Patient Details!");


        } catch (err) {
            setLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
                setNetworkError(err.response.data.message);
            } else {
                setNetworkError("Something Went Wrong. Please Try Again Later.");
            }
        }
    };

    const addHandler = () => {
        EditPetientDetails();
    };
    const resetfield = () => {
        setNetworkError("");
        resetFName();
        resetAge();
        resetContact();
        resetCity();
        setFNameTouch("");
        setAgeTouch("");
        setContactTouch("");
        setCityTouch("");
        setPayment("");
        setGender("");
        resetSystemicHistory("");
        setSystemicHistoryTouch("")
    };


    return (
        <Transition.Root show={editRegFormOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setEditRegFormOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="max-[400px]:w-[100%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[70%] sm:w-[70%] md:w-[70%] xl:w-[50%]">
                                <div className="flex flex-row justify-between items-center">
                                    <div className=" px-4 text-start  bg-white">
                                        <h2 className="text-gray-600 text-lg font-semibold">
                                            Edit Petient Details: ({editPtDetail?.fName})
                                        </h2>
                                    </div>
                                    <div className="px-4 py-3 sm:px-6  ">
                                        <button
                                            type="button"
                                            className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900  focus:outline-none  "
                                            onClick={() => {
                                                setEditRegFormOpen(false);
                                                resetfield();
                                            }}
                                            ref={cancelButtonRef}
                                        >
                                            <XMarkIcon className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>

                                <div className="ml-[7%] mb-6">

                                    <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
                                        <div className="mt-3">
                                            <Input
                                                type="text"
                                                label={
                                                    <>
                                                        {" First Name"}
                                                        <span className="text-red-600 font-bold"> *</span>
                                                    </>
                                                }
                                                placeholder="Enter First Name"
                                                id="fname"
                                                name="fname"
                                                enterValue={enterValueFName}
                                                onChangeHandler={onChangeHandlerFName}
                                                hasError={hasErrorFName}
                                                errorMessage={FNameMessage}
                                                isTouch={fNameTouch}
                                                setIsTouch={setFNameTouch}
                                                onBlurHandler={onBlurHandlerFName}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Input
                                                type="text"
                                                label={
                                                    <>
                                                        {"Age"}
                                                        <span className="text-red-600 font-bold"> *</span>
                                                    </>
                                                }
                                                placeholder="Enter Last Name"
                                                id="Age"
                                                name="Age"
                                                enterValue={enterValueAge}
                                                onChangeHandler={onChangeHandlerAge}
                                                hasError={hasErrorAge}
                                                errorMessage={AgeMessage}
                                                isTouch={ageTouch}
                                                setIsTouch={setAgeTouch}
                                                onBlurHandler={onBlurHandlerAge}
                                            />

                                        </div>
                                    </div>
                                    <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2">

                                        <div className="mt-3">

                                            <div className="flex items-center justify-between mb-2">
                                                <label
                                                    htmlFor="gender"
                                                    className="block text-sm font-bold leading-6 text-gray-900"
                                                >
                                                    Gender
                                                    <span className="text-red-600 font-bold"> *</span>
                                                </label>
                                            </div>

                                            <select
                                                id="gender"
                                                name="gender"
                                                value={gender}
                                                onChange={handleGenderChange}
                                                className="block w-full rounded-md border border-gray-300 py-[10px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                                            >
                                                <option value="" disabled>
                                                    Select Gender
                                                </option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            {genderError && (
                                                <div className="flex ml-[0.5px]">
                                                    <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                                    <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                                        {genderError}
                                                    </p>
                                                </div>
                                            )}

                                        </div>
                                        <div className="mt-3">

                                            <Input
                                                type="text"
                                                label={
                                                    <>
                                                        {"Contact Number"}
                                                        <span className="text-red-600 font-bold"> *</span>
                                                    </>
                                                }
                                                placeholder="Enter Last Name"
                                                id="Contact"
                                                name="Contact"
                                                enterValue={enterValueContact}
                                                onChangeHandler={onChangeHandlerContact}
                                                hasError={hasErrorContact}
                                                errorMessage={ContactMessage}
                                                isTouch={contactTouch}
                                                setIsTouch={setContactTouch}
                                                onBlurHandler={onBlurHandlerContact}
                                            />
                                        </div>
                                    </div>


                                    <div className="w-[92%] gap-x-4 grid grid-cols-1 max-[640px]:grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
                                        <div className="mt-3">
                                            <Input
                                                type="text"
                                                label={
                                                    <>
                                                        {"City"}
                                                        <span className="text-red-600 font-bold"> *</span>
                                                    </>
                                                }
                                                placeholder="Enter City Name"
                                                id="City"
                                                name="City"
                                                enterValue={enterValueCity}
                                                onChangeHandler={onChangeHandlerCity}
                                                hasError={hasErrorCity}
                                                errorMessage={CityMessage}
                                                isTouch={cityTouch}
                                                setIsTouch={setCityTouch}
                                                onBlurHandler={onBlurHandlerCity}
                                            />
                                        </div>
                                        <div className="mt-3">

                                            <div className="flex items-center justify-between mb-2">
                                                <label
                                                    htmlFor="password"
                                                    className="block text-sm font-bold leading-6 text-gray-900"
                                                >
                                                    Payment Mode
                                                    <span className="text-red-600 font-bold"> *</span>
                                                </label>
                                            </div>

                                            <select
                                                id="payment"
                                                name="payment"
                                                value={payment}
                                                onChange={handlePaymentChange}
                                                className="block w-full rounded-md border border-gray-300 py-[10px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                                            >
                                                <option value="" disabled>
                                                    Select Payment Mode
                                                </option>
                                                <option value="1">Cash</option>
                                                <option value="2">UPI</option>
                                                <option value="3">Follow-Up</option>
                                                <option value="4">Complimentary</option>
                                            </select>
                                            {paymentError && (
                                                <div className="flex ml-[0.5px]">
                                                    <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                                    <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                                        {paymentError}
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                    <div className="w-[92%] gap-x-4">
                                        <div className="mt-3">
                                            <Input
                                                type="text"
                                                label={
                                                    <>
                                                        {"Systemic history"}
                                                        <span className="text-red-600 font-bold"> *</span>
                                                    </>
                                                }
                                                placeholder="Enter Systemic History"
                                                id="systemic_history"
                                                name="systemic_history"
                                                enterValue={enterValueSystemicHistory}
                                                onChangeHandler={onChangeHandlerSystemicHistory}
                                                hasError={hasErrorSystemicHistory}
                                                errorMessage={SystemicHistoryMessage}
                                                isTouch={systemicHistoryTouch}
                                                setIsTouch={setSystemicHistoryTouch}
                                                onBlurHandler={onBlurHandlerSystemicHistory}
                                            />

                                        </div>
                                    </div>
                                </div>


                                <div className="text-center">
                                    {loading && (
                                        <strong className="text-blue-800 text-sm  text-center">
                                            {loadingMessage}
                                        </strong>
                                    )}
                                    {networkError && (
                                        <strong className="text-red-600 text-sm  text-center">
                                            {networkError}
                                        </strong>
                                    )}
                                    {successMessage && (
                                        <strong className="text-green-800 text-sm  text-center">
                                            {successMessage}
                                        </strong>
                                    )}
                                </div>
                                <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        disabled={loading}
                                        className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#10a659] hover:bg-[#0d5781] "
                                        onClick={() => {
                                            addHandler();
                                        }}
                                    >
                                        {loading === true ?
                                            (
                                                <MoonLoader
                                                    color="#fff"
                                                    loading={loading}
                                                    size={20}
                                                    className="mx-1"
                                                />
                                            ) : (

                                                <>Update</>
                                            )}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => {
                                            resetfield();
                                            setEditRegFormOpen(false);
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}