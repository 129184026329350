import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { useInput } from "../../../hook/input-hook";
import {
  couponValueNumber,
  emailreg,
  numberreg,
} from "../../../service/validations/validation";
import { MoonLoader } from "react-spinners";
import { fetch } from "../../../service/utils";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import Input from "../../../admin/components/input/Input.component";
import { calculateDaysBetweenDates, formatDated, getFormattedDateTime, padDate } from "../../../service/date.utils";

const isFName = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your First Name" };
  } else {
    return { validated: true, message: "" };
  }
};

const isEmail = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Email" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "Your Email is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};

const isContact = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Contact Number" };
  } else if (!numberreg.test(value)) {
    return { validated: false, message: "Enter 10 digits Contact number" };
  } else {
    return { validated: true, message: "" };
  }
};

const isAge = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your Age" };
  } else if (!couponValueNumber.test(value)) {
    return { validated: false, message: "Your Age is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};

const isCity = (value) => {
  if (value === "") {
    return { validated: false, message: "Please Enter Your City" };
  } else {
    return { validated: true, message: "" };
  }
};

export default function PatientFollowUp({
  patientfollowUpData,
  followUpOpen,
  setFollowUpOpen,
  setClearInputValue,
  setClearSearchPatientData,
  patientId,
  editOptoDetail,
  value,setValue,
}) {
  const cancelButtonRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [fNameTouch, setFNameTouch] = useState("");
  const [lNameTouch, setLNameTouch] = useState("");
  const [emailTouch, setEmailTouch] = useState("");
  const [contactTouch, setContactTouch] = useState("");
  const [ageTouch, setAgeTouch] = useState("");
  const [cityTouch, setCityTouch] = useState("");

  const [gender, setGender] = useState("");
  const [payment, setPayment] = useState("");

  const [genderError, setGenderError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setGenderError("");
  };
  const handlePaymentChange = (event) => {
    setPayment(event.target.value);
    setPaymentError("");
  };

  const {
    hasError: hasErrorFName,
    enterValue: enterValueFName,
    message: FNameMessage,
    onChangeHandler: onChangeHandlerFName,
    reset: resetFName,
    isTouch: isTouchFName,
    setDirect: setDirectFName,
    onBlurHandler: onBlurHandlerFName,
  } = useInput(isFName, setNetworkError, setFNameTouch);

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    message: EmailMessage,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    isTouch: isTouchEmail,
    setDirect: setDirectEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouch);

  const {
    hasError: hasErrorAge,
    enterValue: enterValueAge,
    message: AgeMessage,
    onChangeHandler: onChangeHandlerAge,
    reset: resetAge,
    isTouch: isTouchAge,
    setDirect: setDirectAge,

    onBlurHandler: onBlurHandlerAge,
  } = useInput(isAge, setNetworkError, setAgeTouch);

  const {
    hasError: hasErrorContact,
    enterValue: enterValueContact,
    message: ContactMessage,
    onChangeHandler: onChangeHandlerContact,
    reset: resetContact,
    isTouch: isTouchContact,
    setDirect: setDirectContact,
    onBlurHandler: onBlurHandlerContact,
  } = useInput(isContact, setNetworkError, setContactTouch);
  const {
    hasError: hasErrorCity,
    enterValue: enterValueCity,
    message: CityMessage,
    onChangeHandler: onChangeHandlerCity,
    reset: resetCity,
    isTouch: isTouchCity,
    setDirect: setDirectCity,

    onBlurHandler: onBlurHandlerCity,
  } = useInput(isCity, setNetworkError, setCityTouch);

  useEffect(() => {
    setSuccessMessage("");
  }, [followUpOpen]);

  useEffect(() => {
    setDirectFName(patientfollowUpData?.name);
    setDirectEmail(patientfollowUpData?.email);
    setDirectContact(patientfollowUpData?.contact);
    setDirectAge(patientfollowUpData?.age);
    setDirectCity(patientfollowUpData?.city);
    setGender(patientfollowUpData?.gender)
  }, [followUpOpen, patientId]);

  const followupPetientDetails = async () => {
    setLoading(true);
    setNetworkError("");
    setLoadingMessage("Adding an Entry...");

    if (
      hasErrorFName === true ||
      hasErrorEmail === true ||
      hasErrorContact === true ||
      hasErrorCity === true
    ) {
      if (!isTouchFName) {
        setFNameTouch("Please Enter Your First Name!");
        setLoading(false);
        return false;
      }
      if (!isTouchCity) {
        setCityTouch("Please Enter Your City!");
        setLoading(false);
        return false;
      }
      if (!isTouchEmail) {
        setEmailTouch("Please Enter Your Email!");
        setLoading(false);
        return false;
      }
      if (!isTouchContact) {
        setContactTouch("Please Enter Your Contact Number!");
        setLoading(false);
        return false;
      }
    }
    
    if (!gender) {
      setGenderError("please  gender!")
      setLoading(false);
      return false;
    }
    if (!payment) {
      setPaymentError("Please Select Payment Mode!")
      setLoading(false);
      return false;
    }
    try {
      const body = {
        patientId: patientfollowUpData?._id,
        fName: enterValueFName,
        contact: enterValueContact,
        age: parseInt(enterValueAge),
        gender:gender,
        city: enterValueCity,
        payId: payment
      };

      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(`/followup/add_follow-up`, "post", body, headers);
      if (response.ok === false) {
        setLoading(false);
        setNetworkError(response.data.message);
      }
      setLoading(false);
      setSuccessMessage(response.data.message);

      setFollowUpOpen(false);
      toast.success("Successfully Update Patient Details!");
      setValue("")
      resetfield();
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  const addHandler = () => {
    followupPetientDetails();
  };

  const resetfield = () => {
    setNetworkError("");
    resetFName();
    resetEmail();
    resetContact();
    setFNameTouch("");
    setLNameTouch("");
    setEmailTouch("");
    setContactTouch("");
  };
  const todayDateTime = getFormattedDateTime();
  const visitedDate = formatDated(patientfollowUpData?.visitedDate);

  return (
    <Transition.Root show={followUpOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setFollowUpOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex mt-10 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" max-[400px]:w-[100%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%]">
                <div className="flex flex-row justify-between items-center">
                  <div className=" px-4 text-start  bg-white">
                    <h2 className="text-gray-600 text-lg font-medium    ">
                      Patient Details:{" "} 
                      <span className="text-green-600 text-xl font-bold">
                        {patientfollowUpData?.name}  
                       </span>
                    </h2>
                  </div>
                  <div className="px-4 py-3 sm:px-6  ">
                    <button
                      type="button"
                      className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900  focus:outline-none  "
                      onClick={() => {
                        setFollowUpOpen(false);
                        resetfield();
                        setValue("")
                      }}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>

                <div className="mt-4 w-full px-4 max-[640px]:px-4 sm:px-24 md:px-32 lg:px-44 xl:px-52  ">
                  <div className="container mx-auto">
                    <div className="overflow-x-auto">
                      <table className="min-w-full border-collapse border border-gray-300">
                        <tbody>
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2 w-[40%]">
                              Name
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {patientfollowUpData?.name}
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                              Age/Gender
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {patientfollowUpData?.age} /{" "} 
                              {patientfollowUpData?.gender}
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                              Contact
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {patientfollowUpData?.contact}
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                              City
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {patientfollowUpData?.city}
                            </td>
                          </tr>{" "}
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                              Last Pay Mode
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {patientfollowUpData &&
                              patientfollowUpData?.payId === 1 ? (
                                <>
                                  <span>Cash</span>
                                </>
                              ) : patientfollowUpData?.payId === 2 ? (
                                <>
                                  <span>UPI</span>
                                </>
                              ) : patientfollowUpData?.payId === 3 ? (
                                <>
                                  <span>Follow-Up</span>
                                </>
                              ) : patientfollowUpData?.payId === 4 ? (
                                <>
                                  <span>Complimentary</span>
                                </>
                              ) : null}
                            </td>
                          </tr>
                          
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                            Last Payment Date
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {formatDated(patientfollowUpData?.visitedDate)}
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                           Total days 
                            </td>
                            <td className="border border-gray-300 px-3 py-2 text-[15px] font-semibold">
                              {calculateDaysBetweenDates(todayDateTime,visitedDate) <= 10 ?(
                                <span className="text-green-700">{calculateDaysBetweenDates(todayDateTime,visitedDate)}</span>
                              ):(
                                <>
                              <span className="text-red-600 ">
                               {calculateDaysBetweenDates(todayDateTime,visitedDate)}
                                <span className="text-end text-[10px] ">  {""}{""}{""} (payment reminder)</span>
                               </span>
                              
                               </>)}
                           
                            </td>
                          </tr>
                          <tr className="hover:bg-gray-100">
                            <td className="border border-gray-300 px-3 py-2">
                              Pay Now
                            </td>
                            <td className="border border-gray-300 px-3 py-[2px] text-[15px] font-semibold">
                              <select
                                id="payment"
                                name="payment"
                                value={payment}
                                onChange={handlePaymentChange}
                                className="block w-full rounded-md border border-gray-300 py-[8px] text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium"
                              >
                                <option value="" disabled>
                                  Select Payment Mode
                                </option>
                                <option value="1">Cash</option>
                                <option value="2">UPI</option>
                                <option value="3">Follow-Up</option>
                                <option value="4">Complimentary</option>
                              </select>
                              {paymentError && (
                                <div className="flex ml-[0.5px]">
                                  <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                                  <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                                    {paymentError}
                                  </p>
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                      <div className="mt-2">
                      {calculateDaysBetweenDates(todayDateTime,visitedDate) <= 10 ?(
                                null
                              ):(
                                <>
                              <p className="text-red-600 text-lg leading-16">Note: Please settle the outstanding amount as their follow-up period has expired.</p>
                              
                               </>)}
                        
                      </div>
                  </div>
                  <div className="space-y-4">
                    <div className="text-center">
                      {isLoading && (
                        <strong className="text-blue-800 text-sm  text-center">
                          {loadingMessage}
                        </strong>
                      )}
                      {networkError && (
                        <strong className="text-red-600 text-sm  text-center">
                          {networkError}
                        </strong>
                      )}
                      {successMessage && (
                        <strong className="text-green-800 text-sm  text-center">
                          {successMessage}
                        </strong>
                      )}
                    </div>
                    <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        disabled={isLoading}
                        className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#10a659] hover:bg-[#0d5781] "
                        onClick={() => {
                          addHandler();
                        }}
                      >
                        {isLoading === true ? (
                          <MoonLoader
                            color="#fff"
                            loading={isLoading}
                            size={20}
                            className="mx-1"
                          />
                        ) : (
                          <>Follow Up</>
                        )}
                      </button>

                      <button
                        type="button"
                        className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          resetfield();
                          setFollowUpOpen(false);
                          setValue("")

                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
