import React, { useEffect, useState } from "react";
import {
  EyeIcon,
  FolderOpenIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { fetch } from "../../service/utils";
import OptometristPatientAssessment from "../../website/components/modals/OptometristPatientAssessment";
import { PropagateLoader } from "react-spinners";
import { TbEyeEdit } from "react-icons/tb";
import EditSystemicHistory from "../../website/components/modals/EditSystemicHistory";
import { formatDated } from "../../service/date.utils";
function OptometristList(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState("");
  const [addVisionDetail, setAddVisionDetail] = useState(false);
  const [addPetientDetails, setAddPetientDetails] = useState("");
 const [editRegFormOpen, setEditRegFormOpen] = useState(false);
  const [editPatientDetail, setEditPatientDetail] = useState({})
  const [optoData, setOptoData] = useState([]);

  const [forwardFormOpen, setForwardFormOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPatient, setFilteredPatient] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase() || "";
    setSearchQuery(query);

    const filtered =
      [] &&
      [].length > 0 &&
      []?.filter((pt) =>
        pt?.fname?.toLowerCase().includes(query)
      );
    setFilteredPatient(filtered);
  };

  const getOptoDetails = async (e) => {
    setIsLoading(true);
    setNetworkError("");
    try {
      const token = localStorage.getItem("beenayi_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/reception/get-forward-patient", "get", null, headers);
      setIsLoading(false);
      if (response.data.success === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      } else {
        setOptoData(response.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Something went wrong. Please try again later.";
      setNetworkError(errorMessage);
      toast.error(errorMessage);
      console.log(err);
    }
  };

  useEffect(() => {
    getOptoDetails();
  }, []);

  return (
    <>
      <section className="-mx-4 flex flex-wrap p-4">
        <div className="inline-block w-full  border-[1px] border-gray-300 overflow-auto">
          <div className="overflow-auto">
            <div className="flex min-w-full  items-center justify-between py-2 mb-3 bg-[#0d5781]">
              <div className="font-semibold text-lg px-3 text-white ">
                Optometrist List
              </div>

              <Link to="/admin/dashboard/patient-optometrist_dashboard" className="mr-10">
                <button
                  className="px-3 py-[6px]  text-base font-medium text-white bg-[#10a659] rounded-md shadow-md focus:outline-none focus:ring-2"

                >
                  Optometrist Dashboard
                </button>
              </Link>
            </div>
            {/* <div className="flex min-w-full items-center justify-end  mb-2">

              <div className="border-[1px] border-[#0d5781] w-[30%] rounded-[4px] mr-10">
                <input
                  type="text"
                  placeholder="Search Name, Contact, City..."
                  className="px-2 py-[5px] rounded-[4px] w-full "
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>


            </div> */}
            {isLoading ? (
              <div className="flex justify-center h-screen items-center">
                <PropagateLoader color="#36d7b7" loading={true} size={15} />
              </div>
            ) : networkError ? (
              <div className="text-red-600 text-center mt-16 font-semibold">
                {networkError}
              </div>
            ) : (
              <table className="table table-hover ">
                <thead className="bg-[#0d5781] text-[12px] text-white">
                  <tr>
                    <th scope="col" className="w-[3%] font-medium">
                      #
                    </th>
                    <th scope="col" className="w-[10%] font-medium">
                      Date-Time
                    </th>
                    <th scope="col" className="w-[20%] font-medium">
                      Full Name
                    </th>
                    <th scope="col" className="w-[5%] text-center font-medium">
                      Age
                    </th>
                    <th scope="col" className="w-[7%] text-center font-medium">
                      Gender
                    </th>
                    <th scope="col" className="w-[10%]  font-medium">
                      Contact
                    </th>

                    <th scope="col" className="w-[10%] text-center  font-medium">
                      City
                    </th>
                    <th scope="col" className="w-[10%]  text-center font-medium">
                      Payment
                    </th>
                    <th scope="col" className="w-[15%] text-center  font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {optoData &&
                    optoData?.length > 0 &&
                    optoData?.map((pt, ind) => {
                      return (
                        <tr key={ind} className="text-[12px]    ">
                          <td scope="row" className="text-center border-[1px]">{ind + 1}</td>
                          <td className="text-start border-[1px]">{formatDated(pt?.createdAt)}</td>
                          <td>
                            {pt?.fName}
                          </td>
                          <td className="text-center border-[1px]">{pt?.age}</td>
                          <td className="text-center border-[1px]" >{pt?.gender}</td>
                          <td className="border-[1px]">{pt?.contact}</td>
                          <td className="border-[1px] text-center">
                            {pt?.city}
                          </td>
                          <td className="text-center border-[1px]">
                            {pt?.payId === 1 ? (
                              <>
                                <div className="text-green-500">Cash</div>
                              </>
                            ) : pt.payId === 2 ? (
                              <>
                                <div className="text-orange-500">UPI</div>
                              </>
                            ) : pt.payId === 3 ? (
                              <>
                                <div className="text-yellow-500">Follow up</div>
                              </>
                            ) : pt.payId === 4 ? (
                              <>
                                <div className="text-slate-500">Complimentary</div>
                              </>
                            ) : null}
                          </td>
                          <td className="text-center border-[1px]">

                            <div className="flex justify-center items-center space-x-2">
                              {pt.type === "follow-up" ? (<>
                                <Link to={`/admin/dashboard/patient-details/${pt.patientId}`} target="_blank">
                                  <FolderOpenIcon
                                    className="w-6 h-6 mx-2 text-[#0d5781] hover:text-blue-800 cursor-pointer"

                                  />
                                </Link>
                              </>) : null}
                              <TbEyeEdit
                                className="w-6 h-6 mx-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                                onClick={() => {
                                  setAddVisionDetail(true);
                                  setAddPetientDetails(pt)
                                }}
                              />
                              <PencilSquareIcon
                                className="w-6 h-6 ml-2 text-blue-500 hover:text-blue-800 cursor-pointer"
                                onClick={() => {
                                  setEditPatientDetail(pt);
                                  setEditRegFormOpen(true)
                                }}
                              />
                            </div>
                          </td>

                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </section >
      <OptometristPatientAssessment
        addVisionDetail={addVisionDetail}
        setAddVisionDetail={setAddVisionDetail}
        editProperDetail={addPetientDetails}
      />
<EditSystemicHistory
        editRegFormOpen={editRegFormOpen}
        setEditRegFormOpen={setEditRegFormOpen}
        editPtDetail={editPatientDetail}
        getReceptionDetails={getOptoDetails}
      />
    </>
  );
}

export default OptometristList;
