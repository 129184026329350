/** @format */

import React, { useState, useEffect, useRef } from "react";
import { emailreg } from "../../service/validations/validation";
import { useNavigate } from "react-router-dom";
import { useInput } from "../../hook/input-hook";
import { fetch } from "../../service/utils";
import { setUser } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import logo from "../../../src/assets/logo/logo_beenayi.png";
import Input from "../../admin/components/input/Input.component";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import backgroundImage from '../../assets/img/login.jpg';
import { roles } from "../../service/constant";


const isEmail = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Email" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "Your Email Is Invalid" };
  } else {
    return { validated: true, message: "" };
  }
};
const isPassword = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "Please Enter Your Password" };
  } else {
    return { validated: true, message: "" };
  }
};

function HomeLogin(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [openForgotPwd, setOpenForgotPwd] = useState(false);
  const [isEmailTouchblur, setEmailTouchblur] = useState("");
  const [isPasswordTouch, setisPasswordTouch] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleIdError, setSelectedRoleIdError] = useState("");


  const handleRoleChange = (event) => {
    setSelectedRoleId(event.target.value);
    setSelectedRoleIdError("");
  };

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    message: emailMessage,
    isTouch: isTouchEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouchblur);

  const {
    hasError: hasErrorPassword,
    enterValue: enterValuePassword,
    message: passwordMessage,
    onChangeHandler: onChangeHandlerPassword,
    reset: resetPassword,
    isTouch: isTouchPassword,
    onBlurHandler: onBlurHandlerPwd,
  } = useInput(isPassword, setNetworkError, setisPasswordTouch);

  const [subError, setSubError] = useState("");
  const dispatch = useDispatch();

  const emailRef = useRef(null);
  const pwdRef = useRef(null);

  useEffect(() => {
    setSubError("");
    setEmailTouchblur("");
    setisPasswordTouch("");
  }, [enterValueEmail, enterValuePassword]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setNetworkError("");

    if (hasErrorPassword === true || hasErrorEmail === true) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (!isTouchEmail || !isTouchPassword) {
      if (!isTouchEmail) {
        setEmailTouchblur("Please Enter Your Email");
      }
      if (!isTouchPassword) {
        setisPasswordTouch("Please Enter Your Password");
      }
      setSubError("fields");
      setIsLoading(false);
      return false;
    }
    if (!selectedRoleId) {
      setSelectedRoleIdError("Please Select a Role Name.");
      setIsLoading(false);
      return false;
    }
    try {
      const response = await fetch("/admin/login", "post", {
        email: enterValueEmail,
        password: enterValuePassword,
        roleId: selectedRoleId,
      });
      setIsLoading(false);

      if (response.data.success === true) {
        const userData = {
          user_id: response.data.user.user_id,
          roleId: response.data.user.roleId,
          fname: response.data.user.fname,
          lname: response.data.user.lname,
          user_email: response.data.user.user_email,
        };

        dispatch(setUser(userData));
        localStorage.setItem("beenayi_token", response.data.token);
        resetEmail();
        resetPassword();
        if (userData.roleId === 1) {
          navigate("/admin/dashboard");
        } else if (userData.roleId === 2) {
          navigate("/admin/dashboard");
        } else if (userData.roleId === 3) {
          navigate("/optometrist");
        } else if (userData.roleId === 4) {
          navigate("/registrationDashboard");
        }
        toast.success("Login Successful!");
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  const [type, setType] = useState("password");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const onKeyDownHandlerPwd = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler(e);
    }
  };

  const onKeyDownHandlerEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setSubError("");
    setEmailTouchblur("");
    setisPasswordTouch("");
  }, [enterValueEmail, enterValuePassword]);

  return (
    <>
      <div
        className="flex min-h-full flex-1 flex-col justify-center px-6 pb-12 lg:px-8  bg-cover bg-center"
        // style={{ backgroundImage: `url(${resortImage})` }}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          // opacity: 0.8,
          height: '100vh',
        }}
      >
        <div className="sm:mx-auto sm:w-full mt-2 sm:max-w-sm">
          {/* <img className="mx-auto h-20 w-auto" src={logo} alt="beenayi_clinic" /> */}
          <h2 className="mt-20 text-center text-5xl  font-bold leading-6 tracking-tight text-[#fff]">
            Sign in Now 
          </h2>
          
        </div>

        <div className="my-10 sm:mx-auto sm:w-full sm:max-w-sm opacity-1  ">
          <form className="space-y-4" onSubmit={submitHandler}>
            <div>
              <Input
                type="text"
                label={
                  <>
                    Email <span className="text-red-600 font-bold"> *</span>
                  </>
                }
                placeholder="Enter Your Email"
                id="email"
                name="email"
                ref={emailRef}
                enterValue={enterValueEmail}
                onChangeHandler={onChangeHandlerEmail}
                onKeyDownHandler={onKeyDownHandlerEmail}
                hasError={hasErrorEmail}
                errorMessage={emailMessage}
                isTouch={isEmailTouchblur}
                setIsTouch={setEmailTouchblur}
                onBlurHandler={onBlurHandlerEmail}
              />
            </div>
            <div>
              <Input
                type={type}
                label={
                  <>
                    Password <span className="text-red-600 font-bold"> *</span>
                  </>
                }
                placeholder="Enter Your Password"
                id="password"
                name="password"
                ref={pwdRef}
                enterValue={enterValuePassword}
                onChangeHandler={onChangeHandlerPassword}
                onKeyDownHandler={onKeyDownHandlerPwd}
                hasError={hasErrorPassword}
                errorMessage={passwordMessage}
                isTouch={isPasswordTouch}
                setIsTouch={setisPasswordTouch}
                onBlurHandler={onBlurHandlerPwd}
                handleToggle={handleToggle}
                handleToggleParam="password"
              />
            </div>
            <div>
              <div className="block text-sm font-bold leading-6 text-gray-900 my-2">
                Select Role <span className="text-red-600 font-bold"> *</span>
              </div>
              <select
                value={selectedRoleId}
                onChange={handleRoleChange}
                className="{`block w-full rounded-md border border-gray-300 py-2 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none transition ease-in-out duration-300  sm:text-sm sm:leading-6 px-3 font-medium "
              >
                <option value="" disabled>
                  ---Select a role---
                </option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
              {selectedRoleIdError && (
                <div className="flex ml-[0.5px]">
                  <ExclamationTriangleIcon className="h-4 w-4 mt-[3px] mr-[2px] ml-[0.5px] text-red-600" />{" "}
                  <p className="text-red-600 text-xs leading-16 mt-[3.5px]">
                    {selectedRoleIdError}
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 max-[640px]:grid-cols-1">
              {/* <div className="mx-4 max-[640px]:my-4">
                <button
                  onClick={() => navigate("/")}
                  className="flex w-full justify-center rounded-md bg-red-500  px-2 py-2 text-[16px]  font-medium leading-6 text-white shadow-sm duration-700 hover:bg-[#286663] focus:outline-none focus:hover:bg-[#286663]"
                >
                  Back Home
                </button>
              </div> */}
              <div className="mt-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`bg-[#0d5781] hover:bg-[#10a659] duration-700  w-full rounded-md  px-2 py-2 text-center text-[16px] font-medium text-white shadow-sm focus:outline-none focus:hover:bg-[#10a659] ${isLoading ? "cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? "Loading..." : "Sign In"}
                </button>
              </div>
            </div>
          </form>
          <div className="text-red-500 text-xs leading-16  text-center py-4">
            {subError === "fields" && (
              <strong className="text-red-500 text-xs leading-16 text-center">
                Please Fill All The Fields Appropriately.
              </strong>
            )}
            {networkError && (
              <strong className="text-red-500 text-xs leading-16  text-center">
                {networkError}
              </strong>
            )}
            {isLoading && (
              <strong className="text-[#00009C] text-xs leading-16 text-center">
                Please Wait, Signing In...
              </strong>
            )}
          </div>
          {/* <div className="flex items-center justify-between mt-6">
            {" "}
            <div className="text-sm leading-16">
              <Link
                href="#"
                className="font-semibold text-[#134c49] hover:text-white"
                onClick={() => {
                  setOpenForgotPwd(true);
                }}
              >
                Forgot password?
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default HomeLogin;
