import React, {  useState } from "react";
import { fetch } from "../../../service/utils";

import backgroundImage from "../../../assets/img/bg_eye.jpg";
import Autosuggest from "react-autosuggest";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function SearchPatient({setFollowUpOpen,setPatientfollowUpData,
  value, setValue
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [networkError, setNetworkError] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [searchPatientData, setsearchPatientData] = useState([]);
    const onChange = (event, { newValue }) => {
      setSuggestions(searchPatientData)
        // if (newValue.length <= 6) {
            setValue(newValue);

        // }
    };

    const searchPatientHandler = async (value) => {
        // e.preventDefault();
        setIsLoading(true);
        setNetworkError("");
        setNetworkErrorPatient("");
        if (!value || value.length <= 2) {
            setIsLoading(false);
            return;
        }
        try {
            const token = localStorage.getItem("beenayi_token");
            const headers = { Authorization: `Bearer ${token}` };
            const response = await fetch("/reception/search-patient", "post", {
                query: value,
            },headers);
            setIsLoading(false);
            if (response.data.success === false) {
                setIsLoading(false);
                setNetworkError(response.data.message);
                // toast.error(response.data.message);
            } else {
              // const filteredData = response?.data?.data?.filter((item)=>item.forwardStatus === 0)
              setSuggestions(response?.data?.data);

            }
        } catch (err) {
            setIsLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
                setNetworkError(err.response.data.message);
                // toast.error(err.response.data.message);
            } else {
                setNetworkError("Something Went Wrong. Please Try Again Later.");
            }
        } finally {
        }
    };

    const getSuggestionValue = (suggestion) => {
        setsearchPatientData(suggestion);
        // if (suggestion) {
        // submitSelectedPincode(suggestion.pincode);
        // }
        return suggestion.fName || suggestion.contact 
    };
    const renderSuggestion = (suggestion) => {
        return (
            <>
                <span 
                    style={{
                        display: "block",
                        textAlign: "left",
                        padding: "2px",
                        border: "1px",
                        color:"black",
                        fontSize:"20px",
                        fontWeight:"meduim"
                    }}
                >
                    <div  style={{
                      width:"100%",
                    }} onClick={()=>{setPatientfollowUpData(suggestion);
                      setFollowUpOpen(true)}}>
                       {suggestion.name } {suggestion.contact} {suggestion.city}{" "}</div>
                </span>

            </>
        );
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        searchPatientHandler(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
        setsearchPatientData([]);
    };

    const inputProps = {
        placeholder: "Search Name, Contact",
        value,
        onChange,
    };

    // ============================================== Select pincode  ============================
    const [networkErrorPatient, setNetworkErrorPatient] = useState(false);


  return (
    <>
      <div style={{ width: "100%" }} className="pincode-search relative">
        {/* <XMarkIcon
          className="w-5 h-5 search-close-icon"
          onClick={() => {
            setValue("");
            setsearchPatientData([]);
          }}
        /> */}

        <Autosuggest
          suggestions={[
            ...suggestions?.map((item) => ({
              name: item.fName,
              contact: item.contact,
              city: item.city,
              age:item.age,
              forwardStatus:item.forwardStatus,
              gender:item.gender,
              payId:item.payId,
              updatedAt:item.updatedAt,
              createdAt:item.createdAt,
              visitedDate:item.visitedDate,
              status:item.status,
              _id:item._id
            })),
          ]}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
       
      </div>

      {networkErrorPatient && (
        <div className="flex justify-center mt-2 -mb-2">
          <p className="text-red-600 font-medium text-[15px]">
            **{networkErrorPatient}**
          </p>
        </div>
      )}
    </>
  );
}
