/** @format */

import React, { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useInput } from "../../../hook/input-hook";
import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Input from "../input/Input.component";
import { MoonLoader } from "react-spinners";
import { formatDated } from "../../../service/date.utils";


export default function PrintPrescriptionModal({
  doctorId,
  printOpen,
  setPrintOpen,
  selectedPrint,
}) {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [glassesClose, setGlassesClose] = useState(false);
  const [ophthalmicClose, setOphthalmicClose] = useState(false);
  const [slitLampClose, setSlitLampClose] = useState(false);
  const [fundusClose, setFundusClose] = useState(false);
  const [followUpClose, setFollowUpClose] = useState(false);
  const [diangnoseClose, setDiangnoseClose] = useState(false);
  const [adviseClose, setAdviselose] = useState(false);

  useEffect(() => {
  }, [doctorId, printOpen])

  const printingHandler = () => {

    const PrescriptionContent = `

                    <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <style>
                          body {
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                          }
                   
                          .container {
                            padding: 20px;
                            margin: 0 auto;
                            width: 90%;
                            max-width: 900px;
                          }
                   
                          h1, h2 {
                            text-align: center;
                            margin-bottom: 10px;
                          }
                   
                          .header, .footer, .body-section {
                            width: 100%;
                            margin-bottom: 15px;
                          }
                   
                          .header table, .body-section table {
                            width: 100%;
                            border-collapse: collapse;
                            font-size: 14px;
                            margin-top: 10px;
                          }
                   
                          table th, table td {
                            padding: 8px;
                            border: 1px solid #ddd;
                            text-align: left;
                          }
                   
                          .section-title {
                            font-weight: bold;
                            margin-bottom: 5px;
                            font-size: 14px;
                          }
                   
                          .details {
                            display: flex;
                            justify-content: space-between;
                            font-size: 14px;
                          }
                   
                          .divider {
                            border: none;
                            border-top: 1px solid #000;
                            margin: 10px 0;
                          }
                   
                          .advice, .diagnosis {
                        font-weight: 900;
                        margin-top: 15px;
                      }
                        .dataValue {
                        font-weight: 700;
                        margin-top: 15px;
                      }
                        </style>
                      </head>
                      <body style="margin-top:255px; margin-bottom:70px">
                        <div style="margin-left:70px; margin-right:50px">
                          <!-- Header Section -->
                          <div class="header">
                           
                            <div class="details">
                              <div>
                                <p style="margin-top:0px; margin-bottom:4px"><strong>Patient Name:</strong>${selectedPrint?.patient?.fName}</p>
                                <p style="margin-top:4px; margin-bottom:4px"><strong>Age/Sex:</strong> ${selectedPrint?.patient?.age} years / ${selectedPrint?.patient?.gender}</p>
                                 
                              </div>
                              <div>
                               <p style="margin-top:4px; margin-bottom:0px"><strong>Contact:</strong> ${selectedPrint?.patient?.contact}</p>
                                <p style="margin-top:4px; margin-bottom:0px"><strong>Date:</strong>${formatDated(selectedPrint?.patient?.updatedAt)}</p>
                               
                              </div>
                            </div>
                          </div>
                   
                          <!-- Body Section -->
                          <div class="body-section">
                            <!-- History Section -->
                            <!--<h3 class="section-title">History</h3> -->
                             <div style="margin-top:-2px;">
                              <p>
                              <span class="section-title">Chief Complaints:</span> ${selectedPrint?.optometrist?.patientComplaint?.map((cmp, index) => `<span>
                                       ${cmp},
                                   </span>
                                   ` )?.join("")}
                                   <span style="padding-left:6px">${selectedPrint?.optometrist?.eyeComplaint}</span>
                               </p>
                               </div>
                               <div style="margin-top:-12px;">
                               <p style="margin-top:0px; margin-bottom:4px"><strong>Systemic History:</strong> ${selectedPrint?.patient?.systemicHistory || "N/A"}</p>
                            </div>

                            <div class="details" style="margin-top:-7px;">
                               <p style="margin-top:4px; margin-bottom:0px"><span class="section-title">Right Eye Vision:</span> 
                                <span style="font-weight: 900;"> ${selectedPrint?.optometrist?.rightEyeVision?.unaided === "-" ? "" : ` Vision: ${selectedPrint?.optometrist?.rightEyeVision?.unaided}`}</span>
                              
                              
                               
                              </p>
                                <p style="margin-top:4px; margin-bottom:0px"><span class="section-title">Left Eye Vision:</span>
                                <span style="font-weight: 900;">${selectedPrint?.optometrist?.leftEyeVision?.unaided === "-" ? "" : ` Vision: ${selectedPrint?.optometrist?.leftEyeVision?.unaided}`}</span>
                                 
                                </p>
                               <p style="margin-top:4px; margin-bottom:0px"><span class="section-title">c̅ glasses:</span> <span style="font-weight: 900;"> ${selectedPrint?.optometrist?.rightEyeVision?.withGlasses}, ${selectedPrint?.optometrist?.leftEyeVision?.withGlasses}</span></p>
                              </div>
                           
                   
                            <!-- Glasses Prescription -->
                          <div style="display: flex; flex-direction: row; gap: 10px;">
                           ${glassesClose === true ? "" : (

        `<div style="width: 49%;">
                                <h3 class="section-title">Glasses Prescriptions</h3>
                                <table style="border-collapse: collapse; width: 100%;">
                                    <tr>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Eye</th>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Sph</th>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;text-align: left; font-size:12px">Cyl</th>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Axis</th>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Vision</th>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>OD</strong></td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.rightEyeVision?.sph || "N/A"}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.rightEyeVision?.cyl || "N/A"}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.rightEyeVision?.axis || "N/A"}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.rightEyeVision?.visionResult || "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>OS</strong></td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.leftEyeVision?.sph}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.leftEyeVision?.cyl}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.leftEyeVision?.axis}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.leftEyeVision?.visionResult}</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>Near Vision (Both Eyes)</strong></td>
                                        <td colspan="3" style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: center;">${selectedPrint?.optometrist?.leftEyeVision?.nearVision}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: center;">${selectedPrint?.optometrist?.leftEyeVision?.nearVisionResult}</td>
                                    </tr>
                                </table>
                            </div>`
      )

      }
                        
            
                      
              ${ophthalmicClose === true ? "" : (
        ` <div style="width: 49%;">
                                <h3 class="section-title">Ophthalmic Findings</h3>
                                <table style="border-collapse: collapse; width: 100%;">
                                    <tr>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Eye</th>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">IOP</th>
                                        <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Syringing</th>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>OD</strong></td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.eyePressure?.right}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.syringDone?.right}</td>
                                    </tr>
                                    <tr>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; font-size:12px"><strong>OS</strong></td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.eyePressure?.left}</td>
                                        <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${selectedPrint?.optometrist?.syringDone?.left}</td>
                                    </tr>
                                </table>
                            </div>`
      )}
                    </div>
                   
                            <!-- Examination Section -->
                   <div style="display: flex; flex-direction: row; gap: 10px;">
                    ${slitLampClose === true ? "" : (
        `<div style="width: 49%;">
                            <h3 class="section-title">Slit Lamp Examination</h3>
                            <table>
                              <tr>
                                <th style="width: 20%; padding-top: 2px; padding-bottom: 0px; font-size:12px">Findings</th>
                                <th style="width: 80%; padding-top: 2px; padding-bottom: 0px; font-size:12px">${selectedPrint?.doctor?.slitlamp || "N/A"}</th>
                              </tr>
                              
                             
                            </table>
                        </div>`
      )}
                     ${fundusClose === true ? "" : (
        `<div style="width: 49%;">
          <h3 class="section-title font-size:12px">Fundus Examination</h3>
          <table>
            <tr>
              <th style="width: 20%; padding-top: 2px; padding-bottom: 0px; font-size:12px">Findings</th>
              <th style="width: 80%; padding-top: 2px; padding-bottom: 0px; font-size:12px">${selectedPrint?.doctor?.fundus || "N/A"}</th>
            </tr>


          </table>
        </div>`
      )}
                    </div>
                   
                            <!-- Diagnosis Section -->
                             ${diangnoseClose === true ? "" : (
                      `<div class="section-title">
                        <p>Diagnosis: <span class="dataValue">${selectedPrint?.doctor?.diagnose || "N/A"}</span></p>
                      </div>
                     <hr class="divider">`
      )}
                    <!-- Medicine -->
                    <div style="width: 100%;">
                        <h3 class="section-title">Medicine</h3>
                        <table style="border-collapse: collapse; width: 100%;">
                          <tr>
                            <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Medication Name</th>
                            <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Dose</th>
                            <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Frequency</th>
                            <th style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px; text-align: left; font-size:12px">Duration</th>
                          </tr>
                          <tbody>
                          ${selectedPrint?.doctor?.medications?.map((item, index) => `
                                   <tr key=${index}>
                                       <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.medicationName}</td>
                                       <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.dose}</td>
                                       
                                       <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.frequency?.join('<br>')}</td>
                                       <td style="border: 1px solid black; padding-top: 2px; padding-bottom: 0px;">${item?.duration?.join('<br>')}</td>
                                   </tr>
                               
                               `)?.join("")}
                          </tbody>
                        </table>
                   </div>
                   
                                ${adviseClose === true ? "" : (
        `<div class="section-title">
                        <p>Advice: <span class="dataValue">${selectedPrint?.doctor?.advise || "N/A"}</span></p>
                      </div>
         <hr class="divider">`
      )}
                              ${followUpClose === true ? "" : (
        ` <div class="section-title">
                             <p>Next Follow-up: <span class="dataValue"> After ${selectedPrint?.doctor?.nextFollowupDate || "SOS"}</span></p>
                             
                          </div>`
      )}
                        </div>
                      </body>
                    </html>`;
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    const style = `
                          <style>
                              @page {
                                  margin: 0; /* Removes margins that typically include title, URL, or date */
                              }
                              body {
                                  margin: 0;
                                  padding: 0;
                              }
                          </style>
                      `;

    const content = `
                      ${style}
                      ${PrescriptionContent}
                  `;

    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(content);
    iframe.contentWindow.document.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    iframe.remove();

    setPrintOpen(false);

    setGlassesClose(false)
    setOphthalmicClose(false)
    setSlitLampClose(false)
    setFundusClose(false)
    setAdviselose(false)
    setFollowUpClose(false)
    setDiangnoseClose(false)
  };

  return (
    <Transition.Root show={printOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setPrintOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex mt-10 min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="max-[400px]:w-[100%] max-[640px]:w-[90%] relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[80%] sm:w-[80%] md:w-[80%] xl:w-[80%]">
                <div className="flex flex-row justify-between items-center">
                  <div className=" px-4 text-start  bg-white">
                    <h2 className="text-gray-600 text-lg font-semibold    ">
                      Print Prescription:
                    </h2>
                  </div>
                  <div className="px-4 py-3 sm:px-6  ">
                    <button
                      type="button"
                      className="w-full sm:w-auto justify-center rounded-md border border-transparent bg-white px-1 py-1 text-base font-semibold text-gray-900  focus:outline-none  "
                      onClick={() => {
                        setPrintOpen(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="ml-[3%] mb-6">
                  <div className=" w-[97%] bg-gray-50 px-2 py-3 rounded-lg">

                    <div className="overflow-x-auto">
                      <table className="min-w-full table-auto border-collapse border border-[#0d5781]  text-sm">
                        <tbody>
                          <React.Fragment>
                            <tr className="border border-[#0d5781] ">
                              <td className="px-2 py-2 font-bold w-[7%] border-[1px] whitespace-nowrap">Name</td>
                              <td className="px-2 py-2 font-normal w-[40%] border-[1px] whitespace-nowrap">{selectedPrint?.patient?.fName}</td>
                              <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Age/Gender:</td>
                              <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{selectedPrint?.patient?.age} years / {selectedPrint?.patient?.gender}</td>


                            </tr>
                            <tr className="border border-[#0d5781] ">
                              <td className="px-2 py-2 font-bold w-[10%] border-[1px] whitespace-nowrap">Contact</td>
                              <td className="px-2 py-2 font-normal  w-[10%] border-[1px] whitespace-nowrap">{selectedPrint?.patient?.contact}</td>
                              <td className="px-2 py-2 font-bold w-[5%] border-[1px] whitespace-nowrap">Date & Time</td>
                              <td className="px-2 py-2 font-normal w-[28%] border-[1px] whitespace-nowrap">{formatDated(selectedPrint?.patient?.updatedAt)}</td>

                            </tr>

                          </React.Fragment>
                          {/* );
                                                        })} */}
                        </tbody>
                      </table>
                    </div>

                    {/* Optometrist details */}
                    <div className="overflow-x-auto pt-4">
                      <table className="min-w-full table-auto border-collapse border border-[#0d5781]  text-sm">
                        <tbody>
                          <tr className="border border-[#0d5781] ">
                            <td className="px-2 py-2 font-medium w-[7%] border-[1px] whitespace-nowrap">Complaints</td>
                            <td className="p-2 font-normal w-[40%] border-[1px]" colSpan={5}>{selectedPrint?.optometrist?.patientComplaint?.join(", ")
                            } /, <span className="font-semibold px-2">{selectedPrint?.optometrist?.eyeComplaint}</span></td>
                          </tr>
                          {selectedPrint?.patient?.systemicHistory === undefined || null ? null : (
                            <tr className="border border-[#0d5781] ">
                              <td className="px-2 py-2 font-medium w-[17%] border-[1px] whitespace-nowrap">Systemic History</td>
                              <td className="p-2 font-normal w-[40%] border-[1px]" colSpan={5}>{selectedPrint?.patient?.systemicHistory}</td>


                            </tr>
                          )}
                          <tr className="border border-[#0d5781] ">
                            <td className="px-2 py-2 font-medium w-[7%] border-[1px] whitespace-nowrap">OD Vision</td>
                            <td className="px-2 py-2 font-bold w-[27%] border-[1px] whitespace-nowrap">
                              {selectedPrint?.optometrist?.rightEyeVision?.unaided === "-" ? null : (
                                <>
                                  Vision: {" "}
                                  {selectedPrint?.optometrist?.rightEyeVision?.unaided}
                                </>
                              )},
                              

                            </td>
                            <td className="px-2 py-2 font-medium w-[5%] border-[1px] whitespace-nowrap">OS Vision</td>
                            <td className="px-2 py-2 font-bold w-[27%] border-[1px] whitespace-nowrap">
                              {selectedPrint?.optometrist?.leftEyeVision?.unaided === "-" ? null : (
                                <>
                                  Vision: {" "}
                                  {selectedPrint?.optometrist?.leftEyeVision?.unaided}
                                </>
                              )},
                              {/* {selectedPrint?.optometrist?.leftEyeVision?.withGlasses === "-" ? null : (<>
                                c̅ glasses{" "}
                                {selectedPrint?.optometrist?.leftEyeVision?.withGlasses}
                              </>)} */}

                            </td>
                            <td className="px-2 py-2 font-medium w-[10%] border-[1px] whitespace-nowrap"> c̅ Glasses</td>
                            <td className="px-2 py-2 font-bold  w-[27%] border-[1px] whitespace-nowrap">
                                {selectedPrint?.optometrist?.rightEyeVision?.withGlasses},{" "} {selectedPrint?.optometrist?.leftEyeVision?.withGlasses}
                              </td>

                          </tr>

                          
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div>
                  {/* Glasses and opthalmic finding */}
                  <div className="container mx-auto overflow-x-auto">
                    <div className="flex flex-col  lg:flex-row gap-4 px-6 py-3">
                      <XMarkIcon className="w-6 h-6 border-[1px] p-[1px] rounded-full text-red-600 border-red-600" onClick={() => {
                        if (glassesClose === false) {
                          setGlassesClose(true)
                        } else (
                          setGlassesClose(false)
                        )
                      }} />
                      {!glassesClose && (

                        <div className={`${ophthalmicClose === true ? 'w-full' : 'w-full'}`}>
                          <h3 className="text-base font-semibold">Glasses Prescriptions</h3>
                          <table className="w-full border-collapse text-[12px]">
                            <thead>
                              <tr>
                                <th className="border border-black px-2 py-0.5 text-left">Eye</th>
                                <th className="border border-black px-2 py-0.5 text-center">Sph</th>
                                <th className="border border-black px-2 py-0.5 text-center">Cyl</th>
                                <th className="border border-black px-2 py-0.5 text-center">Axis</th>
                                <th className="border border-black px-2 py-0.5 text-center">Vision</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border border-black px-2 py-0.5 font-semibold">OD</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.rightEyeVision?.sph}</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.rightEyeVision?.cyl}</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.rightEyeVision?.axis}</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.rightEyeVision?.visionResult}</td>
                              </tr>
                              <tr>
                                <td className="border border-black px-2 py-0.5 font-semibold">OS</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.leftEyeVision?.sph}</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.leftEyeVision?.cyl}</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.leftEyeVision?.axis}</td>
                                <td className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.leftEyeVision?.visionResult}</td>
                              </tr>
                              <tr>
                                <td className="border border-black px-1 py-0.5 font-semibold">Near Vision (Both)</td>
                                <td colSpan="3" className="border border-black px-2 py-0.5 text-center">{selectedPrint?.optometrist?.rightEyeVision?.nearVision}</td>
                                <td className="border border-black px-2 py-0.5">{selectedPrint?.optometrist?.rightEyeVision?.nearVisionResult}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* Ophthalmic Findings Table */}
                      {!ophthalmicClose && (

                        <div className={`${glassesClose === true ? 'w-full' : 'w-full'}`}>
                          <h3 className="text-base font-semibold">Ophthalmic Findings</h3>
                          <table className="w-full border-collapse text-[12px]">
                            <thead>
                              <tr>
                                <th className="border border-black p-2 text-left w-1/3">Eye</th>
                                <th className="border border-black p-2 text-left w-1/3">IOP</th>
                                <th className="border border-black p-2 text-left w-[40%]">Syringing</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border border-black p-1 font-semibold">OD</td>
                                <td className="border border-black p-1">{selectedPrint?.optometrist?.eyePressure?.right}</td>
                                <td className="border border-black p-1 max-w-[150px] break-words whitespace-normal">
                                  {selectedPrint?.optometrist?.syringDone?.right}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-black p-1 font-semibold">OS</td>
                                <td className="border border-black p-1">{selectedPrint?.optometrist?.eyePressure?.left}</td>
                                <td className="border border-black p-1 max-w-[150px] break-words whitespace-normal">{selectedPrint?.optometrist?.syringDone?.left}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      <XMarkIcon className="w-6 h-6 border-[1px] p-[1px] rounded-full text-red-600 border-red-600" onClick={() => {
                        if (ophthalmicClose === false) {
                          setOphthalmicClose(true)
                        } else (
                          setOphthalmicClose(false)
                        )
                      }} />
                    </div>

                    <div className=" ml-[3%] w-[95%] px-2 py-3 rounded-lg">

                      <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">

                        <div>
                          <div className="flex items-center justify-between flex-row-reverse">
                            <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                              onClick={() => {
                                if (slitLampClose === false) {
                                  setSlitLampClose(true)
                                } else (
                                  setSlitLampClose(false)
                                )
                              }} />
                          </div>
                          {!slitLampClose && (
                            <>
                              <h3 class="text-base font-bold underline mb-2">Slit Lamp Examination</h3>
                              <table>
                                <tr >
                                  <th className="w-[20%] pt-[2px] pb-[0px] px-[4px] text-[12px] border-1" >Findings</th>
                                  <th className="w-[80%] pt-[2px]  px-[6px] pb-[0px] text-[12px] border-1  ">{selectedPrint?.doctor?.slitlamp}</th>
                                </tr>
                              </table>
                            </>
                          )}
                        </div>

                        <div>
                          <div className="flex items-center justify-between flex-row-reverse">
                            <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                              onClick={() => {
                                if (fundusClose === false) {
                                  setFundusClose(true)
                                } else (
                                  setFundusClose(false)
                                )
                              }} />
                          </div>
                          {!fundusClose && (
                            <>
                              <h3 class="text-base font-bold underline mb-2">Fundus Examination</h3>
                              <table>
                                <tr>
                                  <th className="w-[20%] pt-[2px] pb-[0px] px-[4px] text-[12px] border-1">Findings</th>
                                  <th className="w-[80%] pt-[2px]  px-[6px] pb-[0px] text-[12px] border-1  ">{selectedPrint?.doctor?.fundus}</th>
                                </tr>
                              </table>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-between flex-row-reverse">
                        <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                          onClick={() => {
                            if (diangnoseClose === false) {
                              setDiangnoseClose(true)
                            } else (
                              setDiangnoseClose(false)
                            )
                          }} />
                      </div>
                      {!diangnoseClose && (
                        <>
                         <div class="mt-6">
                            <p class="text-sm font-bold">Diagnosis: <span class="font-normal">{selectedPrint?.doctor?.diagnose || "N/A"}</span></p>

                          </div>
                         
                        </>
                      )}
                      <h3 class="text-base font-bold underline mt-6">Medicine</h3>
                      <table class="w-full border border-gray-300 text-sm">
                        <thead>
                          <tr>
                            <th class="border border-gray-300 px-2 py-1 text-left">Medication Name</th>
                            <th class="border border-gray-300 px-2 py-1 text-left">Dose</th>
                            <th class="border border-gray-300 px-2 py-1 text-left">Frequency</th>
                            <th class="border border-gray-300 px-2 py-1 text-left">Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedPrint?.doctor?.medications?.map((item, ind) =>
                            <tr key={ind}>
                              <td class="border border-gray-300 px-2 py-1">{item?.medicationName}</td>
                              <td class="border border-gray-300 px-2 py-1">{item?.dose}</td>
                              <td className="border border-gray-300 px-2 py-1">
                                {item?.frequency?.map((freq, index) => (
                                  <div key={index}>{freq}</div>
                                ))}
                              </td>
                              <td className="border border-gray-300 px-2 py-1">
                                {item?.duration?.map((dur, index) => (
                                  <div key={index}>{dur}</div>
                                ))}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="flex items-center justify-between flex-row-reverse">
                        <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                          onClick={() => {
                            if (adviseClose === false) {
                              setAdviselose(true)
                            } else (
                              setAdviselose(false)
                            )
                          }} />
                      </div>
                      {!adviseClose && (
                        <>
                          <div class="mt-6">
                            <p class="text-sm font-bold">Advice: <span class="font-normal">{selectedPrint?.doctor?.advise || "N/A"}</span></p>

                          </div>
                        </>
                      )}
                      <div className="flex items-center justify-between flex-row-reverse">
                        <XMarkIcon className="w-6 h-6 mb-[-25px] border-[1px] p-[1px] text-red-600"
                          onClick={() => {
                            if (followUpClose === false) {
                              setFollowUpClose(true)
                            } else (
                              setFollowUpClose(false)
                            )
                          }} />
                      </div>
                      {!followUpClose && (
                        <>
                          <div class="mt-2">
                            <p class="text-sm font-bold mt-2">Next Follow-up: <span class="font-normal">{selectedPrint?.doctor?.nextFollowupDate || "SOS"}</span>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className=" bg-gray-50 px-4 py-3 mb-4 mt-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    disabled={loading}
                    className="inline-flex ml-[4%] w-[92%] justify-center rounded-md  px-3 py-2 text-sm font-medium text-white shadow-sm sm:ml-3 sm:w-auto mt-[15px] bg-[#10a659] hover:bg-[#0d5781] cursor-pointer"
                    onClick={() => {
                      printingHandler();
                    }}
                  >

                    <PrinterIcon
                      className="w-5 h-5 mr-1 text-white"
                    />
                    Print Now
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex ml-[4%] w-[92%] justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setPrintOpen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
